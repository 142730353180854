<template lang="pug">

  .assessments(v-if="survey_templates  && $store.state.user")
    
    .row.mt-3
        
        .col-3
            b-btn(variant="outline-primary", size="sm", @click="create_survey_template()").form-control Create new template
            b-list-group(style="overflow-y: auto", v-if="survey_templates != null").mt-3
                           
                template(v-for="template in survey_templates")
                    b-list-group-item(button,  @click="selected=template;new_mode=false").d-flex.justify-content-between.align-items-center 
                        | {{template.name}}
                        
        .col-9(style="max-width: 800px")
            template(v-if="selected")
                //SurveyTemplate(@saved="getData()" v-model="selected", :edit_mode="false", v-if="!new_mode")
                SurveyTemplate(@saved="getData()" v-model="selected", :edit_mode="true")

</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import SurveyTemplate from '@/components/SurveyTemplate.vue'
import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SurveyTemplates',
  
  data: function () {
      return {
          survey_templates: null,
          selected: null,
          new_template_raw: {groups: [], data_fields: [], name: ""},
          new_template: null,
          new_mode: true
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  
  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
         

        ]),
  },

  
  methods: {

      create_survey_template() {
        this.new_template = Object.assign({}, this.new_template_raw);
        this.selected = this.new_template;
        this.new_mode = true;
      },
    
      getData() {
        
        this.$store.dispatch("start_loading");
        
        Vue.http.get(`${Config.config.base_host}/survey_templates`).then(res => {
            this.survey_templates = res.body;
            
            this.$store.dispatch("stop_loading");
        });

     
      
      }
     
  },
  components: {
      SurveyTemplate,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>