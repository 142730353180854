<template lang="pug">

  .assessments(v-if="surveys != null && $store.state.user")
    
    .row
        | Analytics
        | {{analytics}}


    .row(v-if="this.$route.path.indexOf('tracking') > -1 ")
        .col-3(v-for="status in ['Considered', 'Sent', 'Responded', 'Matched']")
            b-card(no-body).main-card
                .p-2 
                    b {{status == "Responded" ? "Not matched" : status}}
                b-list-group(v-if="surveys != null")
                    template(v-for="survey in get_group(status, surveys)")
                        b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey; selected_item=survey.relations.organization_id").d-flex.justify-content-between.align-items-center.h-mouseover-parent
                            .media
                                b-btn-group()
                                    b-check(v-if="status=='Considered'", v-model="to_send", :value="survey")
                                
                                b-btn-group()
                                    b-check(v-if="status=='Responded'", v-model="to_match", :value="survey")

                                b-btn-group()
                                    b-check(v-if="status=='Sent'", v-model="to_resend", :value="survey")

                                    .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                                .media-body 
                                    div {{survey.relations.organization_id.name}}
                            
                                    div.h-mouseover-hide
                                        template(v-if="survey.confirmed_at")
                                            .text-gray.small Matched {{survey.confirmed_at | utcFrom}}
                                        template(v-else-if="survey.responded_at")
                                            .text-gray.small Not matched {{survey.responded_at | utcFrom}}
                                        template(v-else-if="survey.opened_at")
                                            .text-gray.small Opened {{survey.opened_at | utcFrom}}
                                        template(v-else-if="survey.sent_at")
                                            .text-gray.small Sent {{survey.sent_at | utcFrom}}
                                        template(v-else-if="survey.updated_at")
                                            .text-gray.small Created {{survey.updated_at | utcFrom}}

                                    b-link(variant="outline-secondary" size="sm", to="surveys").small.h-mouseover-show Survey Detail 
                            
            b-btn(variant="outline-primary", v-if="status=='Considered'", @click="send_surveys(to_send)").mt-1.form-control Send Surveys
            b-btn(variant="outline-primary", v-if="status=='Sent'", @click="send_surveys(to_resend, true)").mt-1.form-control Resend Surveys
            b-btn(variant="outline-primary", v-if="status=='Responded'", @click="match_surveys(to_match)").mt-1.form-control Match
                           
      
    .row.mt-3(v-if="this.$route.path.indexOf('tracking') == -1")
        
        .col-12.col-lg-3
           
           
            b-list-group(style="overflow-y: auto", v-if="surveys != null")
                b-list-group-item(:active="selected_survey == null" button, @click="selected_survey=null").mb-2 
                    .label Statistics
                    div
                        h3 {{surveys.length}}
                        .label.gray-info Use Case Surveys<br/>
                    
                template(v-for="survey in surveys")
                    b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey").d-flex.justify-content-between.align-items-center 
                        .media
                            .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                            .media-body {{survey.relations.organization_id.name}}
                        .text-gray.small {{survey.updated_at | utcFrom}} 

        .col-12.col-lg-9(style="max-width: 800px")

            template(v-if="selected_survey == null")
                template(v-if="aggregation")
                    SurveyAggregation(:survey_template="survey_template", v-model="aggregation")
            
            template(v-if="selected_survey")
                b-card()
                    .media
                        .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + selected_survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                        .media-body {{selected_survey.relations.organization_id.name}}, {{selected_survey.relations.organization_id.web_entity}}
                    table.table.small.mt-3

                        tr
                            td() Survey Link
                            td() 
                                b-link(:href="get_survey_link(selected_survey)", target="_blank") {{get_survey_link(selected_survey)}}

                        tr
                            td() Created
                            td() {{selected_survey.created_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Sent at
                            td() 
                                | {{selected_survey.sent_at | moment("DD.MM.YYYY hh:mm")}}
                                b-btn(@click="send_surveys([selected_survey])", variant="outline-primary", size="sm", v-if="!selected_survey.sent_at") Send survey
                        
                        tr
                            td() Opened at
                            td() {{selected_survey.opened_at | moment("DD.MM.YYYY hh:mm")}}
                        
                        tr
                            td() Responded at
                            td() {{selected_survey.responded_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Matched at
                            td() {{selected_survey.confirmed_at | moment("DD.MM.YYYY hh:mm")}}
                template(v-if="selected_survey.responded_at")
                    h4.mt-5 Results
                    Survey(:survey_template="survey_template", @saved="getData()" v-model="selected_survey", :user="selected_survey.relations.owner_id", :edit_mode="false", :editable="true").mt-3
       



</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import SurveyAggregation from '@/components/SurveyAggregation.vue'

import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          analytics: null,
          to_send: [],
          to_match: [],
          to_resend: [],
          survey_template: null,
          selected_survey: null,
          aggregation: null,
          surveys: null,
          save_selected_id: null,
          has_own_assessment: false,
          sort_by: 'name'
    
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  
  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
            'sidebar_project.selected_item'
        ]),
  },

  
  methods: {

      get_group(status, surveys) {
          
          if(status == 'Considered') return surveys.filter(survey => !survey.sent_at);
          if(status == 'Sent') return surveys.filter(survey => survey.sent_at && !survey.responded_at);
          if(status == 'Responded') return surveys.filter(survey => survey.responded_at && !survey.confirmed_at);
          if(status == 'Matched') return surveys.filter(survey => survey.confirmed_at);
      },

      create_new() {
        this.selected_survey = {"relations": {"owner_id": this.$store.state.user}, "custom_data": {}, "owner_id": this.$store.state.user.id};
      },

      get_survey_link(survey) {
          return window.location.protocol + "//" + window.location.host + '/surveys_external/' +  survey.link_token;
      },

      send_surveys(surveys, resend = false) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/send_bulk`, {"survey_ids": surveys.map(x => x._id), "resend": resend}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },

    match_surveys(surveys) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/match_bulk`, {"survey_ids": surveys.map(x => x._id)}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },
    
      getData() {
        
        this.$store.dispatch("start_loading");
        
        Vue.http.get(`${Config.config.base_host}/survey_templates/SurveyTemplate:startup_assessment`).then((res) => {
            this.survey_template = res.body;
            
            this.$store.dispatch("stop_loading");
        });

        Vue.http.post(`${Config.config.base_host}/surveys/search`, {"survey_template_id": ["SurveyTemplate:startup_assessment"],  "enrich": ['organization_id']}).then(res => {
            this.surveys = res.body;

            if(this.save_selected_id) {
                try {
                    this.selected_survey = this.surveys.filter(s => this.save_selected_id == s._id)[0];
                    console.log(this.selected_survey);
                } catch {
                    this.selected_survey = null;
                }

                this.save_selected_id= null;
            }

            this.has_own_assessment = this.surveys.filter(x => x.owner_id == this.$store.state.user.id).length > 0;
            this.$store.dispatch("stop_loading");
        });

 
        /*

        Vue.http.post(`${Config.config.base_host}/surveys/aggregation`, {"survey_template_id": ["SurveyTemplate:startup_assessment"], "topic_id": [this.$route.params.topic_id]}).then(res => {
            this.aggregation = res.body;
            this.$store.dispatch("stop_loading");
        });
        */

        Vue.http.post(`${Config.config.base_host}/surveys/analytics`, {fields: ["created_at", "responded_at"], start_date: "2020-01-01", end_date: "2020-11-01"}).then(res => {
            this.analytics = res.body;
            this.$store.dispatch("stop_loading");
        });
      
      }
     
  },
  components: {
      Survey,
      SurveyAggregation,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }

    .main-card {
        min-height:500px; 
        max-height: calc(100vh - 300px); 
        overflow-y: auto;
    }
</style>